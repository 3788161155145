<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t("tabs.myAccount") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("tabs.myAccount") }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div class="accountTabHeader">
        <ion-avatar
          style="
            text-align: center;
            background-color: #aaa;
            margin: auto;
            height: 150px;
            width: 150px;
            margin-top: 15px;
          "
        >
          <img v-if="user?.photoURL" :src="user?.photoURL" />
          <ion-icon
            v-else
            size="large"
            :icon="personOutline"
            style="height: 100%; color: ghostwhite"
          />
        </ion-avatar>
        <h1 id="myName" style="font-weight: bold">{{ user?.displayName }}</h1>
        <ion-chip @click="showMyProfile()" color="primary">
          <ion-icon :icon="personOutline"></ion-icon>
          <ion-label>{{ $t("myAccount.showMyProfile") }}</ion-label>
        </ion-chip>
      </div>

      <div :style="
            isPlatform('android')
              ? 'margin-left: 0.5rem; margin-right: 0.5rem;'
              : ''
          ">
        <ion-list
          class="settingsSection"
        >
          <ion-item>
            <ion-icon slot="start" :icon="sunnyOutline"></ion-icon>
            <ion-label> {{ $t("myAccount.darkMode") }} </ion-label>
            <ion-toggle
              slot="end"
              color="primary"
              name="blueberry"
              v-model="darkModeEnabled"
              id="themeToggle"
            ></ion-toggle>
          </ion-item>
          <ion-item detail="false" button @click="presentAlertRadio()">
            <ion-icon slot="start" :icon="languageOutline" style=""></ion-icon>
            <ion-label> {{ $t("myAccount.language") }} </ion-label>
            <div slot="end">
              <country-flag :country="$i18n.locale" :rounded="true" />
            </div>
          </ion-item>
        </ion-list>
        <ion-list
          class="settingsSection"   
        >
          <ion-item button @click="openCriteriaSettings()">
            <ion-icon slot="start" :icon="flameOutline" style=""></ion-icon>
            <ion-label> {{ $t("myAccount.criteria") }} </ion-label>
          </ion-item>
          <ion-item button @click="openAccountSettings()">
            <ion-icon slot="start" :icon="keyOutline" style=""></ion-icon>
            <ion-label> {{ $t("myAccount.myAccount") }}</ion-label>
          </ion-item>
          <ion-item button @click="openReportModal()">
            <ion-icon slot="start" :icon="warningOutline" style=""></ion-icon>
            <ion-label>{{ $t("reports.reportProblem") }}</ion-label>
          </ion-item>
        </ion-list>
      </div>

      <div style="text-align: center">
        <ion-button
          mode="ios"
          color="primary"
          @click="logoutUser()"
          expand="block"
          shape="round"
          style="
            max-width: 200px;
            margin: auto;
            height: 3rem;
            margin-top: 3rem;
            margin-bottom: 5rem;
          "
          >{{ $t("myAccount.logout") }}</ion-button
        >
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonChip,
  IonToggle,
  IonToolbar,
  IonTitle,
  IonAvatar,
  modalController,
  alertController,
  isPlatform,
} from "@ionic/vue";
import {
  personOutline,
  sunnyOutline,
  languageOutline,
  flameOutline,
  notificationsOutline,
  fingerPrintOutline,
  logoGitlab,
  warningOutline,
  keyOutline,
} from "ionicons/icons";
import router from "@/router";
import { user, logout } from "@/auth";
import {
  darkModeEnabled,
  disableDarkMode,
  enableDarkMode,
} from "@/settings/settings";

import CriteriaSettingsModal from "@/components/criteria/CriteriaSettingsModal.vue";
import AccountSettingsModal from "@/components/account/AccountSettingsModal.vue";
import ReportModal from "@/report/ReportModal.vue";

import { useI18n } from "vue-i18n";
import { watchEffect } from "@vue/runtime-core";

export default {
  name: "MyAccountTab",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonChip,
    IonToggle,
    IonToolbar,
    IonTitle,
    IonAvatar,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const presentAlertRadio = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("myAccount.language"),
        inputs: [
          {
            type: "radio",
            label: t("languages.slovak"),
            value: "sk",
            checked: locale.value == "sk" ? true : false,
          },
          {
            type: "radio",
            label: t("languages.english"),
            value: "gb",
            checked: locale.value == "gb" ? true : false,
          },
        ],
        buttons: [
          {
            text: t("myAccount.cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("myAccount.save"),
            handler: (value) => {
              locale.value = value;
              localStorage.language = value;
            },
          },
        ],
      });
      return alert.present();
    };

    watchEffect(() => {
      if (darkModeEnabled.value == true) {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    const showMyProfile = async () => {
      router.push({ name: "profile" });
    };

    const logoutUser = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("myAccount.logoutConfirmation"),
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("myAccount.logout"),
            handler: async () => {
              await logout();
              router.replace({ name: "login" });
            },
          },
        ],
      });
      return alert.present();
    };

    const openCriteriaSettings = async () => {
      const modal = await modalController.create({
        component: CriteriaSettingsModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    const openAccountSettings = async () => {
      const modal = await modalController.create({
        component: AccountSettingsModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    const openReportModal = async () => {
      const modal = await modalController.create({
        component: ReportModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      openReportModal,
      presentAlertRadio,
      isPlatform,
      darkModeEnabled,
      showMyProfile,
      openCriteriaSettings,
      openAccountSettings,
      // REFERENCES
      user,

      // FUNCTIONS
      logoutUser,

      personOutline,
      sunnyOutline,
      languageOutline,
      flameOutline,
      notificationsOutline,
      fingerPrintOutline,
      logoGitlab,
      keyOutline,
      warningOutline,
    };
  },
};
</script>


<style scoped>
.accountTabHeader {
  padding: 10px;
  margin-bottom: 30px;
  text-align: center;
}

.settingsSection {
  margin: 1.5rem auto;
  max-width: 500px;
  border-radius: 20px;
}
</style>