
import { defineComponent, ref } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonButtons,
  toastController,
  loadingController,
} from "@ionic/vue";

import CriteriaEditForm from "@/components/criteria/CriteriaEditForm.vue";
import CriteriaEditFormSkeleton from "@/components/criteria/CriteriaEditFormSkeleton.vue";
import {
  errorOccured,
  validationOn,
  validateCriteriaAreNok,
  loadMyCriteria,
  saveMyCriteria,
  loadingMyCriteria,
} from "@/components/criteria";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "CriteriaSettingsModal",
  components: {
    CriteriaEditForm,
    CriteriaEditFormSkeleton,
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonTitle,
    IonButtons,
  },
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const loading = ref(loadingMyCriteria);
    loadMyCriteria();

    let savingLoader: any;
    const presentLoader = async () => {
      savingLoader = await loadingController.create({
        message: t("criteria.saving"),
      });

      await savingLoader.present();
    };

    const openToastOptions = async () => {
      const toast = await toastController.create({
        message: t("criteria.savingFailed"),
        color: "danger",
        position: "bottom",
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
          },
        ],
      });
      return toast.present();
    };

    const closeAndSaveCriteriaSettings = async () => {
      if (errorOccured.value) {
        const modal = await modalController.getTop();
        if (modal) {
          modal.dismiss();
        }
        return;
      }

      if (validateCriteriaAreNok()) {
        validationOn.value = true;
        document.getElementById("sex")?.scrollIntoView({behavior: "smooth"});
        return;
      }

      await presentLoader();
      const { result, error } = await saveMyCriteria();
      if (result == "fail") {
        console.log(error);
        openToastOptions();
      }
      await savingLoader.dismiss();

      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    return { errorOccured, closeAndSaveCriteriaSettings, loading };
  },
});
