
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonToolbar,
  IonItem,
  IonTitle,
  IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  closeOutline,
  lockClosedOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, email, min, max } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import { useI18n } from "vue-i18n";
import { fbAuth } from "@/firebase";
import { user } from "@/auth";
import firebase from "firebase/app";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";
import { openToast } from "@/general/toasts";

export default defineComponent({
  name: "EmailChangeModal",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonItem,
    IonHeader,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const closeModal = async (newEmail?: string) => {
      const modal = await modalController.getTop();
      if (modal) {
        if (newEmail) {
          modal.dismiss({ newEmail: newEmail });
        } else {
          modal.dismiss({ newEmail: null });
        }
      }
    };

    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);

    configure({
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });
    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const aggressiveValidation = ref({ email: false, password: false });
    const showPassword = ref<boolean>(false);

    const changeEmail = async (values: any, actions: any) => {
      fbAuth.languageCode = locale.value;
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.value.email,
          values.password
        );
        await user.value.reauthenticateWithCredential(credential);
        await user.value.updateEmail(values.newEmail);
        user.value = firebase.auth().currentUser;
        openToast("changeEmailSuccess", true, 3000);
        closeModal(values.newEmail);
      } catch (error) {
        if (error.code == "auth/invalid-email") {
          actions.setFieldError("newEmail", t("authValidation.invalidEmail"));
        } else if (error.code == "auth/user-not-found") {
          actions.setFieldError("newEmail", t("authValidation.userNotFound"));
        } else if (error.code == "auth/user-disabled") {
          actions.setFieldError("newEmail", t("authValidation.userDisabled"));
        } else if (error.code == "auth/wrong-password") {
          actions.setFieldError("password", t("authValidation.wrongPassword"));
        } else if (error.code == "auth/email-already-in-use") {
          actions.setFieldError(
            "newEmail",
            t("authValidation.emailAlreadyInUse")
          );
        } else if (error.code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false, 5000);
        } else {
          openToast("changeEmailFail", false, 10000);
        }
      }
    };

    return {
      aggressiveValidation,
      showPassword,
      required,
      email,
      min,
      max,
      changeEmail,
      mailOutline,
      closeModal,
      closeOutline,
      lockClosedOutline,
      eyeOffOutline,
      eyeOutline,
    };
  },
});
