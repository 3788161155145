
import {
  IonPage,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonChip,
  IonToggle,
  IonToolbar,
  IonTitle,
  IonAvatar,
  modalController,
  alertController,
  isPlatform,
} from "@ionic/vue";
import {
  personOutline,
  sunnyOutline,
  languageOutline,
  flameOutline,
  notificationsOutline,
  fingerPrintOutline,
  logoGitlab,
  warningOutline,
  keyOutline,
} from "ionicons/icons";
import router from "@/router";
import { user, logout } from "@/auth";
import {
  darkModeEnabled,
  disableDarkMode,
  enableDarkMode,
} from "@/settings/settings";

import CriteriaSettingsModal from "@/components/criteria/CriteriaSettingsModal.vue";
import AccountSettingsModal from "@/components/account/AccountSettingsModal.vue";
import ReportModal from "@/report/ReportModal.vue";

import { useI18n } from "vue-i18n";
import { watchEffect } from "@vue/runtime-core";

export default {
  name: "MyAccountTab",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonIcon,
    IonChip,
    IonToggle,
    IonToolbar,
    IonTitle,
    IonAvatar,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const presentAlertRadio = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("myAccount.language"),
        inputs: [
          {
            type: "radio",
            label: t("languages.slovak"),
            value: "sk",
            checked: locale.value == "sk" ? true : false,
          },
          {
            type: "radio",
            label: t("languages.english"),
            value: "gb",
            checked: locale.value == "gb" ? true : false,
          },
        ],
        buttons: [
          {
            text: t("myAccount.cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("myAccount.save"),
            handler: (value) => {
              locale.value = value;
              localStorage.language = value;
            },
          },
        ],
      });
      return alert.present();
    };

    watchEffect(() => {
      if (darkModeEnabled.value == true) {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    const showMyProfile = async () => {
      router.push({ name: "profile" });
    };

    const logoutUser = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: t("myAccount.logoutConfirmation"),
        buttons: [
          {
            text: t("buttons.cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("myAccount.logout"),
            handler: async () => {
              await logout();
              router.replace({ name: "login" });
            },
          },
        ],
      });
      return alert.present();
    };

    const openCriteriaSettings = async () => {
      const modal = await modalController.create({
        component: CriteriaSettingsModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    const openAccountSettings = async () => {
      const modal = await modalController.create({
        component: AccountSettingsModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    const openReportModal = async () => {
      const modal = await modalController.create({
        component: ReportModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      openReportModal,
      presentAlertRadio,
      isPlatform,
      darkModeEnabled,
      showMyProfile,
      openCriteriaSettings,
      openAccountSettings,
      // REFERENCES
      user,

      // FUNCTIONS
      logoutUser,

      personOutline,
      sunnyOutline,
      languageOutline,
      flameOutline,
      notificationsOutline,
      fingerPrintOutline,
      logoGitlab,
      keyOutline,
      warningOutline,
    };
  },
};
