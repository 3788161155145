
import { defineComponent, ref } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonButtons,
  IonLabel,
  IonChip,
  IonItem,
  IonList,
  isPlatform,
} from "@ionic/vue";

import EmailChangeModal from "@/components/account/EmailChangeModal.vue";
import PasswordChangeModal from "@/components/account/PasswordChangeModal.vue";

import { user } from "@/auth";

export default defineComponent({
  name: "CriteriaSettingsModal",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonTitle,
    IonButtons,
    IonLabel,
    IonChip,
    IonItem,
    IonList,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const currentEmail = ref(user.value.email);

    const openEmailChangeModal = async () => {
      const modal = await modalController.create({
        component: EmailChangeModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      modal.onDidDismiss().then((data) => {
        if (data.data && data.data.newEmail != null) {
          currentEmail.value = data.data.newEmail;
        }
      });
      return modal.present();
    };

    const openPasswordChangeModal = async () => {
      const modal = await modalController.create({
        component: PasswordChangeModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });

      return await modal.present();
    };

    return {
      openPasswordChangeModal,
      openEmailChangeModal,
      currentEmail,
      user,
      closeModal,
    };
  },
});
