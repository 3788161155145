
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonItem,
  IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  closeOutline,
  lockClosedOutline,
  keyOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, email, min, max, alpha } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import { useI18n } from "vue-i18n";
import { fbAuth } from "@/firebase";
import { user } from "@/auth";
import firebase from "firebase/app";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";
import { openToast } from "@/general/toasts";

export default defineComponent({
  name: "PasswordChangeModal",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonItem,
    IonHeader,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);
    configure({
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });
    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const aggressiveValidation = ref({newPass: false, currentPass: false});
    const showPassword = ref({newPass: false, currentPass: false});

    const changePassword = async (values: any, actions: any) => {
      fbAuth.languageCode = locale.value;
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.value.email,
          values.currentPassword
        );
        await user.value.reauthenticateWithCredential(credential);
        await user.value.updatePassword(values.newPassword);
        user.value = firebase.auth().currentUser;
        openToast("changePasswordSuccess", true, 3000);
        closeModal();
      } catch (error) {
         if (error.code == "auth/wrong-password") {
          actions.setFieldError("currentPassword", t("authValidation.wrongPassword"));
        } else if (error.code == "auth/invalid-password") {
          actions.setFieldError("currentPassword", t("authValidation.invalidEmail"));
        } else if (error.code == "auth/weak-password") {
          actions.setFieldError("newPassword", t("authValidation.weakPassword"));
        } else if (error.code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false, 5000);
        } else {
          openToast("changePasswordFail", false, 10000);
        }
      }
    };

    return {
      aggressiveValidation,
      required,
      email,
      changePassword,
      showPassword,
      mailOutline,
      closeModal,
      closeOutline,
      lockClosedOutline,
      keyOutline,
      eyeOffOutline,
      eyeOutline,
    };
  },
});
