<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t("account.accountSettings") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="closeModal()">{{
            $t("buttons.close")
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{
            $t("account.accountSettings")
          }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div>
        <ion-list style="background: transparent; margin: 1rem 0 1rem 0">
          <ion-item style="--background: transparent; --inner-padding-end: 0">
            <ion-label position="fixed">{{ $t("account.email") }}</ion-label>
            <ion-chip
              style="padding-bottom: 0; margin: 0"
              @click="openEmailChangeModal()"
            >
              <ion-label style="height: 90%">{{ currentEmail }}</ion-label>
            </ion-chip>
          </ion-item>
          <ion-item
            lines="none"
            style="--background: transparent; --inner-padding-end: 0"
          >
            <ion-label position="fixed">{{ $t("account.password") }}</ion-label>
            <ion-chip style="margin: 0" @click="openPasswordChangeModal()">
              <ion-label>●●●●●●●●</ion-label>
            </ion-chip>
          </ion-item>
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import {
  modalController,
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButton,
  IonTitle,
  IonButtons,
  IonLabel,
  IonChip,
  IonItem,
  IonList,
  isPlatform,
} from "@ionic/vue";

import EmailChangeModal from "@/components/account/EmailChangeModal.vue";
import PasswordChangeModal from "@/components/account/PasswordChangeModal.vue";

import { user } from "@/auth";

export default defineComponent({
  name: "CriteriaSettingsModal",
  components: {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonButton,
    IonTitle,
    IonButtons,
    IonLabel,
    IonChip,
    IonItem,
    IonList,
  },
  setup() {
    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const currentEmail = ref(user.value.email);

    const openEmailChangeModal = async () => {
      const modal = await modalController.create({
        component: EmailChangeModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });
      modal.onDidDismiss().then((data) => {
        if (data.data && data.data.newEmail != null) {
          currentEmail.value = data.data.newEmail;
        }
      });
      return modal.present();
    };

    const openPasswordChangeModal = async () => {
      const modal = await modalController.create({
        component: PasswordChangeModal,
        cssClass: isPlatform("desktop") ? "registrationModalClass" : "",
        componentProps: {},
        swipeToClose: true,
      });

      return await modal.present();
    };

    return {
      openPasswordChangeModal,
      openEmailChangeModal,
      currentEmail,
      user,
      closeModal,
    };
  },
});
</script>

<style scoped>
</style>