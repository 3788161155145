<template>
  <ion-page>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-title>{{ $t("account.changePassword") }}</ion-title>
        <ion-buttons slot="end">
          <ion-button color="danger" @click="closeModal()">{{
            $t("buttons.close")
          }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("account.changePassword") }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <div>
        <v-form @submit="changePassword">
          <ion-item lines="none" class="fieldItem">
            <ion-icon
              :icon="lockClosedOutline"
              slot="start"
              style="color: #999"
            ></ion-icon>
            <v-field
              name="currentPassword"
              type="password"
              v-slot="{ field }"
              :validateOnInput="aggressiveValidation.currentPass"
              :validateOnBlur="false"
              rules="required|min:8|max:100"
            >
              <input
                @change="aggressiveValidation.currentPass = true"
                name="currentPassword"
                v-bind="field"
                class="inputField"
                :placeholder="$t('account.currentPassword')"
                :type="showPassword.currentPass ? 'text' : 'password'"
              />
            </v-field>
            <ion-icon
              @click="showPassword.currentPass = !showPassword.currentPass"
              :icon="showPassword.currentPass ? eyeOutline : eyeOffOutline"
              slot="end"
              style="cursor: pointer; color: #999"
            ></ion-icon>
          </ion-item>
          <p class="error">
            <v-error-message name="currentPassword" />
          </p>

          <ion-item lines="none" class="fieldItem">
            <ion-icon
              :icon="keyOutline"
              slot="start"
              style="color: #999"
            ></ion-icon>
            <v-field
              name="newPassword"
              type="password"
              v-slot="{ field }"
              :validateOnInput="aggressiveValidation.newPass"
              :validateOnBlur="false"
              rules="required|min:8|max:100"
            >
              <input
                @change="aggressiveValidation.newPass = true"
                name="newPassword"
                v-bind="field"
                class="inputField"
                :placeholder="$t('account.newPassword')"
                :type="showPassword.newPass ? 'text' : 'password'"
              />
            </v-field>
            <ion-icon
              @click="showPassword.newPass = !showPassword.newPass"
              :icon="showPassword.newPass ? eyeOutline : eyeOffOutline"
              slot="end"
              style="cursor: pointer; color: #999"
            ></ion-icon>
          </ion-item>
          <p class="error">
            <v-error-message name="newPassword" />
          </p>

          <ion-button
            mode="ios"
            type="submit"
            expand="block"
            shape="round"
            color="primary"
            style="margin-top: 15px; height: 3rem"
          >
            {{ $t("buttons.save") }}</ion-button
          >
        </v-form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonIcon,
  IonButton,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonItem,
  IonHeader,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  closeOutline,
  lockClosedOutline,
  keyOutline,
  mailOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons";

import * as V from "vee-validate/dist/vee-validate";
import { defineRule, configure } from "vee-validate/dist/vee-validate";
import { required, email, min, max, alpha } from "@vee-validate/rules";
import { localize, setLocale } from "@vee-validate/i18n";
import { useI18n } from "vue-i18n";
import { fbAuth } from "@/firebase";
import { user } from "@/auth";
import firebase from "firebase/app";

import sk from "@/locales/sk.json";
import en from "@/locales/gb.json";
import { openToast } from "@/general/toasts";

export default defineComponent({
  name: "PasswordChangeModal",
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonItem,
    IonHeader,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    defineRule("required", required);
    defineRule("email", email);
    defineRule("min", min);
    defineRule("max", max);
    configure({
      generateMessage: localize({
        sk: {
          messages: sk.validation,
        },
        en: {
          messages: en.validation,
        },
      }),
    });
    if (localStorage.language) {
      if (localStorage.language == "gb") {
        setLocale("en");
      } else {
        setLocale("sk");
      }
    } else {
      setLocale("sk");
    }

    const aggressiveValidation = ref({newPass: false, currentPass: false});
    const showPassword = ref({newPass: false, currentPass: false});

    const changePassword = async (values: any, actions: any) => {
      fbAuth.languageCode = locale.value;
      try {
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.value.email,
          values.currentPassword
        );
        await user.value.reauthenticateWithCredential(credential);
        await user.value.updatePassword(values.newPassword);
        user.value = firebase.auth().currentUser;
        openToast("changePasswordSuccess", true, 3000);
        closeModal();
      } catch (error) {
         if (error.code == "auth/wrong-password") {
          actions.setFieldError("currentPassword", t("authValidation.wrongPassword"));
        } else if (error.code == "auth/invalid-password") {
          actions.setFieldError("currentPassword", t("authValidation.invalidEmail"));
        } else if (error.code == "auth/weak-password") {
          actions.setFieldError("newPassword", t("authValidation.weakPassword"));
        } else if (error.code == "auth/network-request-failed") {
          openToast("networkConnectionFailed", false, 5000);
        } else {
          openToast("changePasswordFail", false, 10000);
        }
      }
    };

    return {
      aggressiveValidation,
      required,
      email,
      changePassword,
      showPassword,
      mailOutline,
      closeModal,
      closeOutline,
      lockClosedOutline,
      keyOutline,
      eyeOffOutline,
      eyeOutline,
    };
  },
});
</script>

<style scoped>
.error {
  margin-top: 0;
  margin-left: 1.5rem;
  font-weight: light;
  font-size: smaller;
  color: rgb(228, 2, 2);
}

ion-item.fieldItem {
  border: 1px solid #ccc;
  border-radius: 25px;
  margin-top: 5px;
  height: 3rem;
  transition: border-color 0.3s ease-in-out, color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
}
ion-item.fieldItem:hover {
  border-color: #808080;
}

.inputField {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
}
</style>